import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Root = styled.div`
    width: 260px;
    height: 100vh;
    background-color: #F9CB48;
    padding: 20px 0px;
    transition: all 0.5s;
`;

export const activeRoot = css`
    width: 80px !important;
    overflow: hidden;
`;

export const listItemStyles = css`
    margin-bottom: 10px;
`;

export const linkStyles = css`
    color: #333;
    text-decoration: none;

    &:hover {
        color: #000;
        text-decoration: underline;
    }
`;

export const RootHeader = styled.div`  
    display: flex;
    justify-content: flex-end;;
    align-items: center;
    padding-right: 20px;
`;

export const RootList = styled.ul`
    list-style: none;
    padding: 0;
    overflow: hidden;
    > div {
        width: 260px;
    }
`;

export const RootItem = styled.li`
padding-left: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    div:first-child {
        margin-right: 11px;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
`;
export const isActiveClass = css`
    border-left: 9px solid #000;
    position: relative;
    z-index: 1;
    > div {
        margin-left: -9px
    }
`;