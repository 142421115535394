import Sidebar from '@/modules/global/Sidebar/Sidebar';
import React, { useState } from 'react'
import { Navigate, Outlet } from "react-router-dom";
import { Root, RootBody, RootContainer } from './Protected.style';
import MenuHeader from '@/modules/global/MenuHeader/MenuHeader';

const Protected = ({ isLoggedIn }: any) => {
    if (isLoggedIn !== null) {
        if (isLoggedIn === false) {
            return <Navigate to="/" replace />;
        }
    }
    return <Root>
        <Sidebar />
        <RootContainer>
            <MenuHeader />
            <RootBody>
                <Outlet />
            </RootBody>
        </RootContainer>
    </Root>
};

export default Protected;
