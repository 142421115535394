import { maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`
    display: flex;
    justify-content: center;
     
`
export const RootElements = styled.div`
  display: flex;
  @media (max-width: ${maxSizeMobile}) {
    margin-top: 30px;
  }
  > div:first-child {
    border-right: 1px solid black;
  }
  > div:first-child > div:first-child {
    border-bottom: 1px solid black;
  }
  > div:last-child > div:first-child {
    border-bottom: 1px solid black;
  }
  > div:first-child > div:nth-child(2) {
    border-bottom: 1px solid black;
  }
  > div:last-child > div:nth-child(2)  {
    border-bottom: 1px solid black;
  }
  > div > div {
    width: 225px;
    height: 225px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column
  }
  > div > div > img {
        width: 50px;
        height: 50px;
      }
  p {
    padding: 0px 20px;
    text-align: center;
  }
  @media (max-width: ${maxSizeMobile}) {
      > div > div {
        width: 150px;
        height: 150px;
      }
      > div > div > img {
        width: 60px;
        height: 60px;
      }
  }
`

export const RootInput = styled.div`
  width:150px;
  margin-top: 20px;
  input {
    text-align: center;
  }
`