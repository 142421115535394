import { maxSizeMackbook, maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";


export const Root = styled.div`
   > div > div {
    margin: auto;
   height: 400px;
   overflow-y: scroll;

   }
   > div > div::-webkit-scrollbar {
    width: 20px;
    }
    > div > div::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
    > div > div::-webkit-scrollbar-thumb {
        background: #F9CB48;
        border-radius: 10px;
    }
    > div > div::-webkit-scrollbar-thumb:hover {
        background: #C79B1E;
    }
   thead th {
    position: sticky;
    top: 0px;
   }


`

export const RootInfoIcon = styled.div`
    cursor: pointer;
    padding: 2px 0px 2px 10px;
    > svg {
        top: 2px;
    position: relative;
    }
`

export const RootEmpty = styled.div`
    display: flex;
    justify-content: center;
    flex:1;
    margin-top: 20px;
    margin-bottom: 50px;
    flex-direction: column;
    align-items: center;
    > img {
        max-width: 150px;
    }
    > h5 {
        margin-top: -10px;
    }
`

export const RootReplayIcon = styled.div`
    margin-top:10px;   
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
    :hover{
        opacity: 1;
    }
`
export const RootSend = styled.div`
position: absolute;
left: 2px;
top: 50%;
transform: translateY(-50%);
> div {
    background-color: rgb(73, 164, 179);
    border-radius: 30px;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}
`
