import React from 'react';
import { Grid } from '@mui/material';
import DynamicTableIcons from '../DynamicTableIcon/DynamicTableIcons';

const DynamicTableIconsSection = ({ tableIcons, setTableIcons, size, bedrooms, bathrooms, metroStation, type, commune }) => {
  return (
    <Grid item xs={12} mt={3} mb={3}>
      <DynamicTableIcons
        tableIcons={tableIcons}
        setTableIcons={setTableIcons}
        size={size}
        bedrooms={bedrooms}
        bathrooms={bathrooms}
        metroStation={metroStation}
        type={type}
        commune={commune}
      />
    </Grid>
  );
};

export default DynamicTableIconsSection;
