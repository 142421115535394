import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CircularProgress,
  Paper,
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PencilIcon from '@/components/Icons/PencilIcon';
import { separadorMiles } from '@/utils/separadorMiles';
import moment from 'moment';
import config from '@/config';
import { RootEmpty } from './Table.style';

const Table = ({
  loading,
  properties,
  onClickPencil
}) => {

  if (loading) {
    return (
      <RootEmpty>
        <CircularProgress />
      </RootEmpty>
    );
  }

  if (properties.length === 0) {
    return (
      <RootEmpty>
        <img src={require('@/assets/img/house-subasta-3d.webp')}  />
        <Typography variant="subtitle2">No tienes propiedades...</Typography>
      </RootEmpty>
    );
  }

  return (
    <Root>
      <Card elevation={8} style={{ borderRadius: 20 }}>
        <TableContainer component={Paper}>
          <TableMui stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell><Typography variant="body2">Foto</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Dirección</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Descripción</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Tipo</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Fecha Subasta</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2">Precio</Typography></StyledTableCell>
                <StyledTableCell><Typography variant="body2"></Typography></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties.map((property) => (
                <StyledTableRow key={property.id}>
                  <StyledTableCell component="th" scope="row">
                      {property.images.length > 0 ? (
                      <img
                        src={config.API_URL + `/${property.images[0].url}`}
                        alt="Property"
                        style={{ width: 100 }}
                      />
                    ) : (
                      <img
                        src="https://via.placeholder.com/100"
                        alt="No image"
                        style={{ width: 100 }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body2">{property.address}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body2">{property.description}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body2">Casa</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body2">{moment(property.auctionDate).format('DD/MM/YYYY') || 'N/A'}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body2">$ {separadorMiles(property.price) || 'N/A'}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body2" onClick={() => onClickPencil(property.id)}>
                      <PencilIcon style={{ width: 15 }} />
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableMui>
        </TableContainer>
      </Card>
    </Root>
  );
};

export default Table;

const Root = styled('div')({
  // Your Root styles here
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: 'relative',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
