import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Container,
  Button,
} from '@mui/material';
import EditField from '@/components/Inputs/EditField/EditField';
import ImageEditor from '@/components/Inputs/ImageEditor/ImageEditor';
import config from '@/config';

const PropertyView = ({ id, fetchProperties, onClose }) => {
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editFields, setEditFields] = useState<any>({});
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(config.API_URL + `/properties/${id}`);
        setProperty(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching property', error);
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  const handleFieldChange = (field, value) => {
    setEditFields({ ...editFields, [field]: value });
    setProperty({ ...property, [field]: value });
  };

  const handleImagesChange = (images) => {
    setEditFields({ ...editFields, images });
    setProperty({ ...property, images });
  };

  const handleSubmit = async () => {
    try {
      const sendData = { ...editFields };

      // Debugging: log JSON contents
      console.log("sendData", sendData);

      const response = await axios.put(config.API_URL + `/properties/${id}`, sendData, {
        headers: { 'Content-Type': 'application/json' },
      });

      setProperty(response.data);
      setEditFields({});
      setSuccessMessage(true);
      setTimeout(() => setSuccessMessage(false), 3000);
      fetchProperties();
    } catch (error) {
      console.error('Error updating property', error);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!property) {
    return (
      <Container>
        <Typography variant="h5" color='rgba(0,0,0,0.4)'>Selecciona una propiedad</Typography>
      </Container>
    );
  }

  return (
    <Card elevation={8} sx={{ borderRadius: 5, maxWidth: 800 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <EditField
            label="Dirección"
            value={property.address}
            onSave={(value) => handleFieldChange('address', value)}
            isEdited={editFields?.address || null}
          />
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <EditField
              label="Descripción"
              value={property.description}
              onSave={(value) => handleFieldChange('description', value)}
              isEdited={editFields?.description || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Tamaño (m²)"
              value={property.size}
              onSave={(value) => handleFieldChange('size', Number(value))}
              type="number"
              isM2
              isEdited={editFields?.size || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Precio"
              value={property.price}
              onSave={(value) => handleFieldChange('price', Number(value))}
              type="number"
              isPrice
              isEdited={editFields?.price || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Fecha de Subasta"
              value={property.auctionDate}
              onSave={(value) => handleFieldChange('auctionDate', value)}
              type="date"
              isDate
              isEdited={editFields?.auctionDate || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Venta de Pago"
              value={property.paymentSale}
              onSave={(value) => handleFieldChange('paymentSale', value)}
              isEdited={editFields?.paymentSale || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Garantía de Precio"
              value={property.priceGuarantee}
              onSave={(value) => handleFieldChange('priceGuarantee', Number(value))}
              type="number"
              isPrice
              isEdited={editFields?.priceGuarantee || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Baños"
              value={property.bathrooms}
              onSave={(value) => handleFieldChange('bathrooms', Number(value))}
              type="number"
              isEdited={editFields?.bathrooms || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Habitaciones"
              value={property.bedrooms}
              onSave={(value) => handleFieldChange('bedrooms', Number(value))}
              type="number"
              isEdited={editFields?.bedrooms || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Rol SII"
              value={property.rolSii}
              onSave={(value) => handleFieldChange('rolSii', value)}
              isEdited={editFields?.rolSii || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Estacionamientos"
              value={property.parking}
              onSave={(value) => handleFieldChange('parking', Number(value))}
              type="number"
              isEdited={editFields?.parking || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Propiedad Ocupada"
              value={property.occupiedProperty ? 'Sí' : 'No'}
              onSave={(value) => handleFieldChange('occupiedProperty', value === 'Sí')}
              isEdited={editFields?.occupiedProperty || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Mandante"
              value={property.mandante}
              onSave={(value) => handleFieldChange('mandante', value)}
              isEdited={editFields?.mandante || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Días de Pago"
              value={property.payDays}
              onSave={(value) => handleFieldChange('payDays', Number(value))}
              type="number"
              isEdited={editFields?.payDays || null}
            />
          </Grid>
          <Grid item xs={12}>
            <EditField
              label="Ubicación (Google Maps URL)"
              value={property.location}
              onSave={(value) => handleFieldChange('location', value)}
              isEdited={editFields?.location || null}
            />
          </Grid>
          <Grid item xs={12} mt={4} mb={4} pl={3} pr={3}>
            <Typography variant="body1" mb={3}><b>Imagenes: </b>{property.images.length}</Typography>
            {/* <ImageEditor
              images={property.images}
              onImagesChange={handleImagesChange}
            /> */}
          </Grid>
          <Grid item xs={12} container justifyContent={'flex-end'} mb={3}>
            {Object.keys(editFields).length > 0 && (
              <Typography variant="body2" color="error" marginLeft={2}>
                Necesitas guardar los cambios
              </Typography>
            )}
            {successMessage && (
              <Typography variant="body2" color="success" marginLeft={2}>
                Guardado con éxito
              </Typography>
            )}
          </Grid>
          <Grid container item xs={12} display="flex" alignItems="center" justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="contained" color="inherit" onClick={onClose}>
                Cerrar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSubmit} style={{ width: 200 }}>
                Guardar Cambios
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

    </Card>
  );
};

export default PropertyView;
