import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const IconSelectorContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  padding: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
`;

export const IconList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const IconItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const IconImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const IconSVG = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const iconSVGStyle = css`
  svg {
    width: 40px;
    height: 40px;
  }
  * {
    fill: rgb(249, 203, 72);
  }
`;

export const AddIconButton = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
