import React, { useEffect, useState, useRef } from 'react';
import { Root, RootElements, RootInput } from './DynamicTableIcons.style';
import { Autocomplete, TextField } from '@mui/material';
import IconSelector from '@/components/Icons/IconSelector/IconSelector';
import axios from 'axios';
import config from '@/config';

const DynamicTableIcons = ({ 
    tableIcons, 
    setTableIcons, 
    bathrooms, 
    size,
    bedrooms,
    metroStation,
    type,
    commune,
}) => {
    console.log("commune",commune)
    const [icons, setIcons] = useState([]);
    
    const autocompleteOptions = [
        'Área', 'Dormitorios', 'TipoPropiedad', 'Baños', 'EstacionMetro', 'Comuna',
        'Año de construcción',
        'Estacionamientos',
        'Regularizado',
        'Hipotecario',
        'Calefacción central',
        'Afecta a expropiación',
        'Planos del inmueble',
        'Piso en que se encuentra',
        'Agua potable',
    ];

    const inputRefs = useRef({});

    useEffect(() => {
        axios.get(config.API_URL + '/icons')
            .then(response => {
                setIcons(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the icons!', error);
            });
    }, []);

    useEffect(() => {
        setTableIcons(prevValues => {
            const newValues = { ...prevValues };
            Object.keys(newValues).forEach(key => {
                if (newValues[key].isDynamic) {
                    switch (newValues[key].isDynamic) {
                        case 'Área':
                            newValues[key].value = `${size} m²`;
                            break;
                        case 'Dormitorios':
                            newValues[key].value = `${bedrooms} Dormitorio${bedrooms > 1 ? 's' : ''}`;
                            break;
                        case 'Baños':
                            newValues[key].value =`${bathrooms} Baño${bathrooms > 1 ? 's' : ''}`;
                            break;
                        case 'EstacionMetro':
                            newValues[key].value = metroStation || 'EstacionMetro';
                            break;
                        case 'TipoPropiedad':
                            newValues[key].value = type || 'TipoPropiedad';
                            break;
                        case 'Comuna':
                            newValues[key].value = commune || 'Comuna';
                            break;
                        default:
                            break;
                    }
                }
            });
            return newValues;
        });
    }, [bathrooms, size, bedrooms, metroStation, type, commune]);

    const handleIconSelect = (icon, id) => {
        console.log('Selected icon in handleIconSelect:', icon); // Verifica el ícono seleccionado
        setTableIcons(prevState => {
            const updatedState = { ...prevState, [id]: { ...prevState[id], icon: icon.id } };
            console.log('Updated tableIcons in handleIconSelect:', updatedState); // Verifica el estado actualizado
            return updatedState;
        });
    };


    const handleAddIcon = () => {
        console.log('Agregar nuevo icono');
        // Aquí puedes añadir la lógica para agregar un nuevo icono
    };

    const blurTextField = (id) => {
        if (inputRefs.current[id]) {
            inputRefs.current[id].blur();
        }
    };

    const handleAutocompleteChange = (event, value, id) => {
        console.log("value",value)
        let newValue = value;
        let isDynamic = null;
        if (autocompleteOptions.includes(value)) {
            switch (value) {
                case 'Área':
                    newValue = `${size} m²`;
                    isDynamic = 'Área';
                    break;
                case 'Dormitorios':
                    newValue = `${bedrooms} Dormitorio${bedrooms > 1 ? 's' : ''}`;
                    isDynamic = 'Dormitorios';
                    break;
                case 'Baños':
                    newValue = `${bathrooms} Baño${bathrooms > 1 ? 's' : ''}`;
                    isDynamic = 'Baños';
                    break;
                case 'EstacionMetro':
                    newValue = metroStation;
                    isDynamic = 'EstacionMetro';
                    break;
                case 'TipoPropiedad':
                    newValue = type;
                    isDynamic = 'TipoPropiedad';
                    break;
                case 'Comuna':
                    newValue = commune;
                    isDynamic = 'Comuna';
                    break;
                default:
                    break;
            }
            blurTextField(id); // Eliminar el enfoque cuando se selecciona un valor dinámico
        }
        setTableIcons(prevState => ({
            ...prevState,
            [id]: { ...prevState[id], value: newValue, isDynamic: isDynamic }
        }));
    };

    const handleTextFieldChange = (event, id) => {
        const newValue = event.target.value;
        setTableIcons(prevState => {
            if (prevState[id].isDynamic) {
                return {
                    ...prevState,
                    [id]: { value: '', isDynamic: false, icon: prevState[id].icon }
                };
            } else {
                return {
                    ...prevState,
                    [id]: { value: newValue, isDynamic: false, icon: prevState[id].icon }
                };
            }
        });
    };

    const handleTextFieldFocus = (id) => {
        setTableIcons(prevState => {
            if (prevState[id].isDynamic) {
                return {
                    ...prevState,
                    [id]: { value: '', isDynamic: false, icon: prevState[id].icon }
                };
            }
            return prevState;
        });
        if (inputRefs.current[id]) {
            inputRefs.current[id].focus();
        }
    };

    return (
        <Root>
            <RootElements>
                <div>
                    {['1', '2', '3'].map((id) => (
                        <div key={id}>
                            <IconSelector
                                icons={icons}
                                onSelect={(icon) => handleIconSelect(icon, id)}
                                onAddIcon={handleAddIcon}
                                selectedIconId={tableIcons[id]?.icon}
                            />
                            <RootInput>
                                <Autocomplete
                                    freeSolo
                                    options={autocompleteOptions}
                                    value={tableIcons[id]?.value || ''}
                                    disableClearable
                                    onChange={(event, value) => handleAutocompleteChange(event, value, id)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={(ref) => (inputRefs.current[id] = ref)}
                                            variant='standard'
                                            style={{ backgroundColor: tableIcons[id]?.isDynamic ? 'yellow' : 'white' }}
                                            onFocus={() => handleTextFieldFocus(id)}
                                            onChange={(event) => handleTextFieldChange(event, id)}
                                        />
                                    )}
                                />
                            </RootInput>
                        </div>
                    ))}
                </div>
                <div>
                    {['4', '5', '6'].map((id) => (
                        <div key={id}>
                         <IconSelector
                                icons={icons}
                                onSelect={(icon) => handleIconSelect(icon, id)}
                                onAddIcon={handleAddIcon}
                                selectedIconId={tableIcons[id]?.icon}
                            />
                            <RootInput>
                                <Autocomplete
                                    freeSolo
                                    options={autocompleteOptions}
                                    value={tableIcons[id]?.value || ''}
                                    disableClearable
                                    onChange={(event, value) => handleAutocompleteChange(event, value, id)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={(ref) => (inputRefs.current[id] = ref)}
                                            variant='standard'
                                            style={{ backgroundColor: tableIcons[id]?.isDynamic ? 'yellow' : 'white' }}
                                            onFocus={() => handleTextFieldFocus(id)}
                                            onChange={(event) => handleTextFieldChange(event, id)}
                                        />
                                    )}
                                />
                            </RootInput>
                        </div>
                    ))}
                </div>
            </RootElements>
        </Root>
    );
};

export default DynamicTableIcons;
