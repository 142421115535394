import { spacing, theme } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`
    padding-top: ${spacing.vertical}px;
    padding-left: ${spacing.horizontal}px;
    padding-right: ${spacing.horizontal}px;
    display: flex;
    gap: 40px;
`

export const RootTable = styled.div`
margin-top: ${spacing.vertical}px;
`

export const RootContent = styled.div`
    display: flex;
    justify-content: space-between;
`
export const RootsideBodyTop = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`
export const RootBody = styled.div`
    flex:1;    
`
export const RootSideBody = styled.div`
    max-width: 800px;
`
export const RootModal = styled.div`
height: 100vh;
overflow-y: auto;
padding-top: 80px;
> div  {
    padding-bottom: 180px;
    justify-content: center !important;
      margin: auto;
    align-items: center !important;
    display: flex;
    flex-direction: column;
  }
`
