import { Button, Card, CircularProgress, Grid, Snackbar, TextField, ThemeProvider, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ButtonChange, Root, RootButton, RootContent, RootCreated, RootError, RootForgot, RootForm, RootHeader } from './Login.style'
// import login from '@/api/users/login'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '@/provider/AuthProvider'
import login from '@/api/users/login'
import { themeDark } from '@/theme'
import { Fn } from '@/utils/validations';
import ModalForgotPass from '@/modules/home/ModalForgotPass/ModalForgotPass'
import Logo from '@/components/Logo/Logo'

const Login = ({ setIsAuth }) => {
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [error, setError] = useState('')
    const [load, setLoad] = useState(false)
    const [message, setMessage] = useState(null)

    const auth = useAuth()

    const navigate = useNavigate()

    const onSubmit = async () => {
        setError('')

        if (!email) {
            return setError('Te falta ingresar un correo')
        }
        if (!Fn.validateEmail(email)) {
            return setError('Debes ingresar un correo válido')
        }
        if (!password) {
            return setError('Te falta ingresar una contraseña')
        }
        if (password.length < 6) {
            return setError('La contraseña debe ser mínimo 6 letras')
        }

        setLoad(true)
        const res = await login({ email, password })
        console.log("res login =>", res)
        if (!res) {
            setLoad(false)
            return setError('Error en el sistema')
        }
        if (!res.data) {
            if (res.error) {
                setLoad(false)
                if (res.error.errorMessage) {
                    return setError(res.error.errorMessage)
                } else {
                    setLoad(false)
                    return setError('Error')
                }
            } else {
                setLoad(false)
                return setError('Error')
            }
        }

        localStorage.setItem('token', res.token)
        setIsAuth(true)
        setMessage('¡Datos correctos! Ingresando...')
        setTimeout(() => {
            setLoad(false)
            auth.signin(res.data, () => {
                navigate('property')
            })
        }, 2000)
    }

    return (
        <ThemeProvider theme={themeDark}>
            <Root>
                <RootContent>
                    <Card elevation={140} style={{ padding: 30, borderRadius: 30 }}>
                        <RootForm>
                            <Grid container>
                                <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
                                    <Logo width={200} height={200} />
                                </Grid>
                            </Grid>
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Correo'
                                style={{ marginTop: 20 }}
                                fullWidth
                                variant='filled'
                            />
                            <TextField
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='Contraseña'
                                type="password"
                                style={{ marginTop: 20 }}
                                fullWidth
                                variant='filled'
                            />
                        </RootForm>
                        <RootButton>
                            {/* <Link to={'register'}>
                                <Button style={{ marginRight: 20 }} className={ButtonChange}>
                                    <Typography variant='body2'>Registrate aquí</Typography>
                                </Button>
                            </Link> */}
                            <Button onClick={onSubmit} variant='outlined' className={ButtonChange}>
                                {
                                    load
                                        ? <CircularProgress />
                                        : <Typography variant='body2'>Ingresar</Typography>
                                }
                            </Button>
                        </RootButton>
                        <RootError>
                            {error && <Typography variant='body2' color="red">{error}</Typography>}
                        </RootError>
                        <RootCreated>
                            <Typography variant='caption'>Versión 0.1.0 Alpha</Typography>
                        </RootCreated>
                    </Card>
                </RootContent>
                <Snackbar
                    open={message}
                    autoHideDuration={6000}
                    onClose={() => setMessage(null)}
                    message={message}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </Root>
        </ThemeProvider>
    )
}

export default Login