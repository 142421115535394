import React, { useState } from 'react'
import { Root, RootBarline, RootLogo, RootPerfilContainer, RootPerfilMenu, toolTipPopper } from './MenuHeader.style'
import { useNavigate } from 'react-router-dom';
import { Avatar, Tooltip, Typography } from '@mui/material';
import { useAuth } from '@/provider/AuthProvider';

import MenuExpand from './MenuExpand/MenuExpand';
import MenuMobile from './MenuMobile/MenuMobile';
import Logo from '@/components/Logo/Logo';


interface Props {
  hidePerfil?: any
  setOpenModalPerfilML?: any
}

const MenuHeader: React.FC<Props> = ({
  hidePerfil,
  setOpenModalPerfilML,
}) => {
  const [showMenuMobile, setShowMenuMobile] = useState(false)

  return (
    <Root>
      <LogoSvg />
      <RootPerfilContainer>
        {!hidePerfil && <PerfilMenu setOpenModalPerfilML={setOpenModalPerfilML} />}
      </RootPerfilContainer>
      <BarlineSvg />
    </Root>
  )
}

const PerfilMenu = ({
  setOpenModalPerfilML
}) => {
  const navigate = useNavigate()
  const auth = useAuth()

  const logOut = () => {
    auth.signout(() => {
      navigate('/')
    })
  }

  return (
    <Tooltip placement='bottom-end'
      title={<MenuExpand setOpenModalPerfilML={setOpenModalPerfilML} logOut={logOut} />} classes={{ tooltip: toolTipPopper }}>
      <RootPerfilMenu>
        <div>
          <Typography variant='h4'>Hola {auth.user?.name}</Typography>
        </div>
        <Avatar
          style={{
            width: 54,
            height: 54
          }}
        />
      </RootPerfilMenu>
    </Tooltip>
  )
}

const LogoSvg = () => {
  return (
    <RootLogo>
      <Logo 
        width={200}
        height={130}
      />
    </RootLogo>
  )
}

const BarlineSvg = () => {
  return (
    <RootBarline>
      <svg width="100%" height="14" viewBox="0 0 100% 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="14" fill="#F9CB48" />
      </svg>
    </RootBarline>
  )
}

export default MenuHeader