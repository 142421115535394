import { maxSizeMackbook, maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";

export const RootItem = styled.div`
   display:flex;
   max-height: 1036px;
   @media (max-width: ${maxSizeMobile}) {
      flex-direction: column;
   }
`

export const RootDogHouse = styled.div`
   padding-bottom: 66%;
   width: 100%;
   height: 0px;
   background-size: 100% auto;
   background-position: center;
   background-repeat: no-repeat;
   @media (min-width: ${maxSizeMackbook}) {
      padding-bottom: 66%;
   }
   @media (max-width: ${maxSizeMobile}) {
      padding-bottom: 66%;
   }
`
export const RootItemTitle = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center; 
   max-height: 636px;
   margin-left: 40px;
   margin-right: 40px;
   margin-top: 90px;
   margin-bottom: 50px;
   @media (max-width: ${maxSizeMobile}) {
      > h2 {
         font-size: 25px;
      }
   }
`
