import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress,
  Snackbar,
  Alert,
  AlertColor,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import config from '@/config';
import { css } from '@emotion/css';

const ImageUpload = ({ onClose, setUploadImages, uploadImages }) => {
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  
  console.log("files", files)
  console.log("images", images)

  useEffect(() => {
    void isUpdateImages();
  }, []);

  const isUpdateImages = () => {
    setImages((prevFiles) =>
      uploadImages.map((i) => config.API_URL + '/' + i.url.replace(/\\/g, '/'))
    );
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    const newImages = selectedFiles.map((file) => URL.createObjectURL(file));

    setImages((prevImages) => [...prevImages, ...newImages]);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDeleteImage = (imageToDelete: string) => {
    const imageIndex = images.indexOf(imageToDelete);
    const newImages = images.filter((_, index) => index !== imageIndex);
    const newFiles = files.filter((_, index) => index !== imageIndex);

    setImages(newImages);
    setFiles(newFiles);
  };

  const handleConfirm = async () => {
    if(files.length === 0) {
      setUploadImages((prevFiles) => (
        [...prevFiles, ...images.map((i) => ({ url: i }))]
      ));
      
      return ;
    }

    setConfirmLoading(true);
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('images', file);
      });

      const res = await axios.post(`${config.API_URL}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("res",res)
      setUploadImages((prevFiles) => (
        [...prevFiles, ...res.data]
      ));

      setSnackbarMessage('Imágenes cargadas exitosamente');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      onClose();
    } catch (error) {
      setSnackbarMessage('Error al cargar las imágenes');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error al confirmar la subida de imágenes:', error);
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLIElement>) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggedIndex === null) return;
    const updatedImages = [...images];
    const [removed] = updatedImages.splice(draggedIndex, 1);
    updatedImages.splice(index, 0, removed);

    setImages(updatedImages);
    setDraggedIndex(null);
  };

  return (
    <Box sx={{ width: '100%', textAlign: 'center', marginTop: 2 }}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="upload-image"
        multiple
        type="file"
        onChange={handleImageUpload}
      />
      <label htmlFor="upload-image">
        <Button variant="contained" component="span" startIcon={<UploadIcon />} disabled={confirmLoading}>
          Subir Imágenes
        </Button>
      </label>
      <ImageList sx={{ width: '100%', marginTop: 2 }} cols={3} rowHeight={164}>
        {images.map((image, index) => (
          <ImageListItem
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={handleDragOver}
            onDrop={() => handleDrop(index)}
            className={rootImage}
          >
            <img src={image} alt={`upload-${index}`} />
            <ImageListItemBar
              sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 8px' }}
              title={<Typography variant="caption" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', padding: '2px 4px' }}>{index + 1}</Typography>}
              actionIcon={
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label={`delete ${index}`}
                  onClick={() => handleDeleteImage(image)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Button onClick={onClose} variant="contained" sx={{ marginTop: 2 }}>
        Cancelar
      </Button>
      {images.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          startIcon={confirmLoading ? <CircularProgress size={24} /> : <CheckIcon />}
          onClick={handleConfirm}
          sx={{ marginTop: 2 }}
          disabled={confirmLoading}
        >
          Confirmar
        </Button>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ImageUpload;

export const rootImage = css`
  cursor: move;
  :hover {
    box-shadow: 0 0 5px #ffcc00;
  }
  img {
    
    max-height: 100px;
    width: 100%;
  }
`