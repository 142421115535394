import { maxSizeMobile } from '@/theme';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RootModalImages = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    > div {
        padding: 20px;
    }
`;

export const RootCarrousel = styled.div`
  min-width: 300px;
  @media (min-width: ${maxSizeMobile}) {
    margin-right: 40px;
  }
`