import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    CircularProgress,
    Container,
    Button,
    Modal,
} from '@mui/material';
import config from '@/config';
import FormFields from './FormFields/FormFields';
import DocumentSection from './DocumentSection/DocumentSection';
import DynamicTableIconsSection from './DynamicTableIconsSection/DynamicTableIconsSection';
import ImageUpload from './ImageUpload/ImageUpload';
import { RootCarrousel, RootModalImages } from './Form.style';
import Carrousel from './Carrousel/Carrousel';

const CreatePropertyForm = ({ fetchProperties }) => {
    const [formFields, setFormFields] = useState({
        address: '',
        description: '',
        size: '1',
        price: '',
        auctionDate: '',
        salePercentage: '',
        priceGuarantee: '',
        bathrooms: '1',
        bedrooms: '1',
        metroStation: '',
        type: '',
        region: '',
        typeOfSale: '',
        rolSii: '',
        parking: '',
        occupiedProperty: false,
        mandante: '',
        daysToPay: '',
        urlLocation: '',
        commune: '',
    });
    console.log("formFields", formFields)
    const [imagenes, setImagenes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [regions, setRegions] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [filteredCommunes, setFilteredCommunes] = useState([]);
    const [metroStations, setMetroStations] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [propertyTypeSales, setPropertyTypeSales] = useState([]);
    const [mandantes, setMandantes] = useState([]);
    const [storage, setStorage] = useState([{
        headerTitle: 'Antecedentes de la propiedad',
        subtitles: [
            { text: 'Bases', file: null },
            { text: 'Procedimiento', file: null },
        ],
    },
    {
        headerTitle: 'Condiciones del remate o subasta',
        subtitles: [
            { text: 'Bases', file: null },
            { text: 'Procedimiento', file: null },
        ],
    }]);
    const tableIconsDefaultData = {
        '1': { value: `${formFields.size} m²`, icon: 1, isDynamic: 'Área' },
        '2': { value: `${formFields.bedrooms} Dormitorio${Number(formFields.bedrooms) > 1 ? 's' : ''}`, icon: 3, isDynamic: 'Dormitorios' },
        '3': { value: formFields.type, icon: 5, isDynamic: 'TipoPropiedad' },
        '4': { value: `${formFields.bathrooms} Baño${Number(formFields.bathrooms) > 1 ? 's' : ''}`, icon: 2, isDynamic: 'Baños' },
        '5': { value: formFields.metroStation, icon: 4, isDynamic: 'EstacionMetro' },
        '6': { value: formFields.commune, icon: 7, isDynamic: 'Comuna' }
    }
    const [tableIcons, setTableIcons] = useState(tableIconsDefaultData);
    const [modalImages, setModalImages] = useState(false);

    const [images, setImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadImages, setUploadImages] = useState([]);
    console.log("uploadImages", uploadImages)
    console.log("selectedImages", selectedImages)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [regionRes, communeRes, metroStationsRes, propertyTypesRes, propertyTypeSaleRes, mandantesRes] = await Promise.all([
                    axios.get(`${config.API_URL}/location/regions`),
                    axios.get(`${config.API_URL}/location/communes`),
                    axios.get(`${config.API_URL}/metro-station`),
                    axios.get(`${config.API_URL}/property-type`),
                    axios.get(`${config.API_URL}/property-type-sale`),
                    axios.get(`${config.API_URL}/property-mandante`),
                ]);
                setRegions(regionRes.data);
                setCommunes(communeRes.data);
                setMetroStations(metroStationsRes.data);
                setPropertyTypes(propertyTypesRes.data);
                setPropertyTypeSales(propertyTypeSaleRes.data);
                setMandantes(mandantesRes.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (formFields.region) {
            setFilteredCommunes(communes.filter((commune) => commune.region.id === formFields.region));
        } else {
            setFilteredCommunes([]);
        }
    }, [formFields.region, communes]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields({
            ...formFields,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormFields({
            ...formFields,
            [name]: checked,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const sendForm:any = Object.assign({}, formFields);
        sendForm.price = Number(formFields.price);
        sendForm.salePercentage = Number(formFields.salePercentage);
        sendForm.priceGuarantee = Number(formFields.priceGuarantee);
        sendForm.size = Number(formFields.size);
        sendForm.rolSii = Number(formFields.rolSii);
        sendForm.parking = Number(formFields.parking);
        sendForm.daysToPay = Number(formFields.daysToPay);
        sendForm.metroStation = !formFields.metroStation ? null : formFields.metroStation;
        try {
            const data = {
                ...sendForm,
                images: uploadImages.map(image => image.id),
                storage: [],
                tableIcons: Object.values(tableIcons).map(icon => ({
                    icon: icon.icon,
                    title: icon.value,
                })),
            };
            console.log("data", data)
            await axios.post(`${config.API_URL}/properties`, data);
            fetchProperties();

            // Reset form fields
            setFormFields({
                address: '',
                description: '',
                size: '1',
                price: '',
                auctionDate: '',
                salePercentage: '',
                priceGuarantee: '',
                bathrooms: '1',
                bedrooms: '1',
                metroStation: '',
                type: '',
                region: '',
                typeOfSale: '',
                rolSii: '',
                parking: '',
                occupiedProperty: false,
                mandante: '',
                daysToPay: '',
                urlLocation: '',
                commune: '',
            });
            setImagenes([]);
            setStorage([{
                headerTitle: 'Antecedentes de la propiedad',
                subtitles: [
                    { text: 'Bases', file: null },
                    { text: 'Procedimiento', file: null },
                ],
            },
            {
                headerTitle: 'Condiciones del remate o subasta',
                subtitles: [
                    { text: 'Bases', file: null },
                    { text: 'Procedimiento', file: null },
                ],
            }]);
            setTableIcons(tableIconsDefaultData);
            setSuccessMessage(true);
            setTimeout(() => setSuccessMessage(false), 3000);
        } catch (error) {
            console.error('Hubo un error al subir la propiedad!', error);
        } finally {
            setLoading(false);
        }
    };


    console.log('selectedImages', selectedImages);
    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const response = await axios.get(config.API_URL + '/images'); // Reemplaza con la URL correcta de tu API
            setImages(response.data);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const handleAddImage = async (formData) => {
        try {
            const response = await axios.post(config.API_URL + '/images', formData); // Reemplaza con la URL correcta de tu API
            fetchImages(); // Refrescar la lista de imágenes
            return response;
        } catch (error) {
            console.error('Error adding images:', error);
            throw error;
        }
    };

    const handleDeleteImage = async (id) => {
        try {
            await axios.delete(config.API_URL + `/images/${id}`); // Reemplaza con la URL correcta de tu API
            setImages((prevImages) => prevImages.filter((image) => image.id !== id));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleSelectedImagesChange = (selectedImages) => {
        setSelectedImages(selectedImages);
    };
    const handleImageChange = (images) => {
        setImagenes(images);
    };

    const handleStorageChange = (docs) => {
        setStorage(docs);
    };

    const handleTableIconChange = (icons) => {
        setTableIcons(icons);
    };
    return (
        <Container>
            <Card elevation={8} sx={{ borderRadius: 5 }}>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Modal open={modalImages}>
                                <RootModalImages>
                                    <Card>
                                        <ImageUpload
                                            onClose={() => setModalImages(false)}
                                            setUploadImages={setUploadImages}
                                            uploadImages={uploadImages}
                                        />
                                    </Card>
                                </RootModalImages>
                            </Modal>
                            <Grid item xs={12} mb={5} container justifyContent={'center'} alignItems={'center'} mt={7}>
                                <RootCarrousel>
                                    <Carrousel images={uploadImages} />
                                </RootCarrousel>
                            </Grid>
                            <Grid item xs={12} mb={5} container justifyContent={'center'} alignItems={'center'} mt={7}>
                                <Button onClick={() => setModalImages(true)} variant='contained'>Seleccionar imagenes</Button>
                            </Grid>
                            <Grid item xs={12} mb={5}>
                                <hr />
                            </Grid>
                            <FormFields
                                formFields={formFields}
                                handleInputChange={handleInputChange}
                                handleCheckboxChange={handleCheckboxChange}
                                regions={regions}
                                filteredCommunes={filteredCommunes}
                                metroStations={metroStations}
                                propertyTypes={propertyTypes}
                                propertyTypeSales={propertyTypeSales}
                                mandantes={mandantes}
                            />
                            <Grid item xs={12} mt={3} mb={3}>
                                <hr />
                            </Grid>
                            <DynamicTableIconsSection
                                tableIcons={tableIcons}
                                setTableIcons={handleTableIconChange}
                                size={formFields.size}
                                bedrooms={formFields.bedrooms}
                                bathrooms={formFields.bathrooms}
                                metroStation={metroStations.find((m) => m.id === formFields.metroStation)?.name}
                                type={propertyTypes.find((t) => t.id === formFields.type)?.name}
                                commune={communes.find((c) => c.id === formFields.commune)?.name}
                            />
                            {/* <Grid item xs={12} mt={3} mb={3}>
                                <hr />
                            </Grid> */}
                            {/* <DocumentSection
                                storage={storage}
                                setStorage={handleStorageChange}
                            /> */}
                            <Grid item xs={12} container justifyContent={'flex-end'} mb={3}>
                                {successMessage && (
                                    <Typography variant="body2" color="success" marginLeft={2}>
                                        Guardado con éxito
                                    </Typography>
                                )}
                            </Grid>
                            <Grid container item xs={12} display="flex" alignItems="center" justifyContent={'flex-end'} spacing={1}>
                                <Grid item>
                                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                        {loading ? <CircularProgress size={24} /> : 'Crear Propiedad'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Container>
    );
};

export default CreatePropertyForm;
