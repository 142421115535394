import React, { useEffect, useRef, useState } from 'react';
import { IconImage, IconItem, IconList, IconSelectorContainer, IconSVG, iconSVGStyle, DropdownContainer, AddIconButton } from './IconSelector.style';
import { Button } from '@mui/material';
import config from '@/config';
import { ReactSVG } from 'react-svg';

const IconSelector = ({ icons, onSelect, onAddIcon, selectedIconId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (icon) => {
        onSelect(icon);
        setIsOpen(false);
    };

    const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen]);

    const renderIcon = (icon) => {
        if (icon.type === 'svg') {
            return (
                <IconSVG>
                    <ReactSVG
                        src={`${config.API_URL}/${icon.url}`}
                        className={iconSVGStyle}
                    />
                </IconSVG>
            );
        }
        return <IconImage src={icon.url} alt={icon.name} />;
    };

    // Find the selected icon based on selectedIconId
    const selectedIcon = icons.find(icon => icon.id === selectedIconId);

    return (
        <IconSelectorContainer ref={containerRef}>
            <Button onClick={toggleDropdown}>
                {selectedIcon ? renderIcon(selectedIcon) : 'Select Icon'}
            </Button>
            {isOpen && (
                <DropdownContainer>
                    <IconList>
                        {icons.map((icon) => (
                            <IconItem key={icon.id} onClick={() => handleSelect(icon)}>
                                {renderIcon(icon)}
                            </IconItem>
                        ))}
                    </IconList>
                    {/* <AddIconButton onClick={onAddIcon}>Agregar Icono</AddIconButton> */}
                </DropdownContainer>
            )}
        </IconSelectorContainer>
    );
};

export default IconSelector;
