import React from 'react';
import { Grid, TextField, MenuItem, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';

const FormFields = ({
    formFields,
    handleInputChange,
    handleCheckboxChange,
    regions,
    filteredCommunes,
    metroStations,
    propertyTypes,
    propertyTypeSales,
    mandantes
}) => {
    return (
        <>
            <Grid item xs={12}>
                <TextField
                    label="Precio en UF"
                    placeholder='Ingresa el monto aquí'
                    fullWidth
                    name="price"
                    value={formFields.price}
                    onChange={handleInputChange}
                    type="number"
                    required
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Región"
                    fullWidth
                    name="region"
                    value={formFields.region}
                    onChange={handleInputChange}
                    required
                >
                    {regions?.map((region) => (
                        <MenuItem key={region.id} value={region.id}>
                            {region.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Comuna"
                    fullWidth
                    name="commune"
                    value={formFields.commune}
                    onChange={handleInputChange}
                    required
                    disabled={!formFields.region}
                >
                    {filteredCommunes?.map((commune) => (
                        <MenuItem key={commune.id} value={commune.id}>
                            {commune.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Dirección"
                    fullWidth
                    name="address"
                    value={formFields.address}
                    onChange={handleInputChange}
                    required
                    placeholder='Ej: Calle 1512, Depto 633'
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Fecha de Subasta"
                    fullWidth
                    name="auctionDate"
                    value={formFields.auctionDate}
                    onChange={handleInputChange}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Porcentaje cobro por venta"
                    fullWidth
                    name="salePercentage"
                    value={formFields.salePercentage}
                    onChange={handleInputChange}
                    type="number"
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Abona una garantía de"
                    fullWidth
                    name="priceGuarantee"
                    value={formFields.priceGuarantee}
                    onChange={handleInputChange}
                    type="number"
                    required
                    placeholder='$X.000.000'
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Estación de Metro Más Cercana"
                    fullWidth
                    name="metroStation"
                    value={formFields.metroStation}
                    onChange={handleInputChange}
                >
                    {metroStations.map((station) => (
                        <MenuItem key={station.id} value={station.id}>
                            {station.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Tipo de Propiedad"
                    fullWidth
                    name="type"
                    value={formFields.type}
                    onChange={handleInputChange}
                    required
                >
                    {propertyTypes?.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                            {e.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Tipo de Venta"
                    fullWidth
                    name="typeOfSale"
                    value={formFields.typeOfSale}
                    onChange={handleInputChange}
                    required
                >
                    {propertyTypeSales?.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                            {e.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Rol SII"
                    fullWidth
                    name="rolSii"
                    value={formFields.rolSii}
                    onChange={handleInputChange}
                    required
                    placeholder='Ej: XXXXXXXX-X'
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Dormitorios"
                    fullWidth
                    name="bedrooms"
                    value={formFields.bedrooms}
                    onChange={handleInputChange}
                    type="number"
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Baños"
                    fullWidth
                    name="bathrooms"
                    value={formFields.bathrooms}
                    onChange={handleInputChange}
                    type="number"
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Estacionamientos"
                    fullWidth
                    name="parking"
                    value={formFields.parking}
                    onChange={handleInputChange}
                    type="number"
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Tamaño terreno (m²)"
                    fullWidth
                    name="size"
                    value={formFields.size}
                    onChange={handleInputChange}
                    type="number"
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    select
                    label="Mandante"
                    fullWidth
                    name="mandante"
                    value={formFields.mandante}
                    onChange={handleInputChange}
                    required
                >
                    {mandantes?.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                            {e.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Días corridos para pagar adjudicación"
                    fullWidth
                    name="daysToPay"
                    value={formFields.daysToPay}
                    onChange={handleInputChange}
                    type="number"
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Ubicación (Google Maps URL)"
                    fullWidth
                    name="urlLocation"
                    value={formFields.urlLocation}
                    onChange={handleInputChange}
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="occupiedProperty"
                            checked={formFields.occupiedProperty}
                            onChange={handleCheckboxChange}
                            color="primary"
                        />
                    }
                    label="Propiedad Ocupada"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Descripción"
                    fullWidth
                    name="descripcion"
                    value={formFields.descripcion}
                    onChange={handleInputChange}
                    required
                    multiline
                    rows={4}
                />
            </Grid>
        </>
    );
};

export default FormFields;
