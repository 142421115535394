import { createTheme } from "@mui/material";

export const maxSizeMackbookNum = 1723
export const maxSizeTabletNum = 1024
export const maxSizeMobileNum = 414

export const maxSizeMackbook = maxSizeMackbookNum + 'px'
export const maxSizeTablet = maxSizeTabletNum + 'px'
export const maxSizeMobile = maxSizeMobileNum + 'px'

export const spacing = {
  horizontal: 24,
  vertical: 30
}

export const theme = createTheme({
  typography: {
    fontSize: 18,
    fontFamily: [
      'LeagueSpartan',
      'sans-serif',
    ].join(',')
  },
  palette: {
    primary: {
      main: '#060A23',
      dark: '#060A23'
    },
    background: {
      default: ''
    },
    warning: {
      main: '#FAE845'
    }
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.8)'
        }
      }
    }
  }
});

theme.typography.h1 = {
  fontSize: 70,
  fontWeight: 700,
  letterSpacing: -3,
}
theme.typography.h2 = {
  fontSize: 50,
  fontWeight: 700,
}
theme.typography.h3 = {
  fontWeight: 700,
  fontSize: 53,
  lineHeight: 1.15,
}
theme.typography.h4 = {
  fontSize: 30,
  fontWeight: 500,
}

theme.typography.h5 = {
  fontSize: 26,
  fontWeight: 'normal',
}

theme.typography.h6 = {
  fontSize: 22,
  fontWeight: '600',
}


theme.typography.subtitle1 = {
  fontSize: 18,
  fontWeight: 'normal',
}

theme.typography.subtitle2 = {
  fontSize: 22,
  fontWeight: 'initial',
}

theme.typography.caption = {
  fontSize: 12,
  letterSpacing: 1.2,
}

theme.typography.body1 = {
  fontSize: 20,
}

theme.typography.body2 = {
  fontSize: 18,
}




export const themeDark = createTheme({
  typography: {
    fontSize: 24,
    fontFamily: [
      'LeagueSpartan',
      'sans-serif',
    ].join(',')
  },
  palette: {
    primary: {
      main: '#fff',
      dark: '#060A23'
    },
    background: {
      default: '#060A23'
    }
  }
});

themeDark.typography.h1 = {
  fontSize: 70,
  fontWeight: 700,
  letterSpacing: -3,
  color: themeDark.palette.primary.main
}
themeDark.typography.h2 = {
  fontSize: 50,
  fontWeight: 700,
  color: themeDark.palette.primary.main
}
themeDark.typography.h3 = {
  fontWeight: 700,
  fontSize: 53,
  color: themeDark.palette.primary.main,
  lineHeight: 1.15,
}
themeDark.typography.h4 = {
  fontSize: 30,
  fontWeight: 500,
  color: themeDark.palette.primary.dark
}

themeDark.typography.h5 = {
  fontSize: 26,
  fontWeight: 'normal',
  color: themeDark.palette.primary.dark
}

themeDark.typography.h6 = {
  fontSize: 22,
  fontWeight: '600',
  color: themeDark.palette.primary.dark
}


themeDark.typography.subtitle1 = {
  fontSize: 18,
  fontWeight: 'normal',
  color: themeDark.palette.primary.dark
}

themeDark.typography.subtitle2 = {
  fontSize: 24,
  fontWeight: 'initial',
  color: themeDark.palette.primary.dark
}

themeDark.typography.caption = {
  fontSize: 12,
  letterSpacing: 1.2,
  color: themeDark.palette.primary.dark
}

themeDark.typography.body1 = {
  fontSize: 32,
  color: themeDark.palette.primary.dark,  
}

themeDark.typography.body2 = {
  fontSize: 24,
  color: themeDark.palette.primary.dark
}