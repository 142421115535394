import React, { useEffect, useState } from 'react'
import { Root, RootBody, RootContent, RootModal, RootSideBody, RootsideBodyTop, RootTable } from './Property.style'
import { Button, Grid, Modal, Typography } from '@mui/material'
import Table from '@/modules/property/Table/Table'
import Form from '@/modules/property/Form/Form'
import axios from 'axios'
import PropertyView from '@/modules/property/PropertyView/PropertyView'
import CloseIcon from '@/components/Icons/CloseIcon'
import WindowIcon from '@/components/Icons/WindowIcon'
import RightScreenIcon from '@/components/Icons/RightScreenIcon'
import config from '@/config';

const Property = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [activeCreate, setActiveCreate] = useState(false);
  const [inModal, setInModal] = useState(true);

  const fetchProperties = async () => {
    try {
      const response = await axios.get(config.API_URL + '/properties');
      console.log("response", response)
      setProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProperties();
  }, []);


  const handleRowClick = async (id) => {
    setSelectedProperty(id);
  };

  const handleClickAdd = () => {
    setActiveCreate(true)
    setSelectedProperty(null)
  }

  const handleOnClose = () => {
    setSelectedProperty(null)
    setActiveCreate(false)
  }

  return (
    <>
      <Root>
        <RootBody>
          <RootContent>
            <Typography variant='h2'>Propiedades</Typography>
            <Button variant='outlined' onClick={handleClickAdd} style={{height: 50}}>Agregar</Button>
          </RootContent>
          <RootTable>
            <Table
              loading={loading}
              properties={properties}
              onClickPencil={handleRowClick}
            />
          </RootTable>
        </RootBody>
        {
          activeCreate && !selectedProperty
            ? !inModal
              ? (<CreateForm
                onClose={() => handleOnClose()}
                onClickWindow={() => setInModal(true)}
                inModal={inModal}
                fetchProperties={fetchProperties} />)
              : (<Modal open={true}>
                <RootModal>
                  <CreateForm
                    onClose={() => handleOnClose()}
                    onClickWindow={() => setInModal(false)}
                    inModal={inModal}
                    fetchProperties={fetchProperties} />
                </RootModal>
              </Modal>
              )
            : null

        }
        {
          selectedProperty
            ? !inModal
              ? (<PropertyCard
                onClose={() => setSelectedProperty(null)}
                onClickWindow={() => setInModal(true)}
                inModal={inModal}
                fetchProperties={fetchProperties}
                selectedProperty={selectedProperty} />)
              : (<Modal open={true}>
                <RootModal>
                  <PropertyCard
                    onClose={() => setSelectedProperty(null)}
                    onClickWindow={() => setInModal(false)}
                    inModal={inModal}
                    fetchProperties={fetchProperties}
                    selectedProperty={selectedProperty} />
                </RootModal>
              </Modal>
              )
            : null
        }
      </Root>
    </>
  )
}

const PropertyCard = ({ fetchProperties, selectedProperty, onClickWindow, onClose, inModal }) => {
  const colorWhite = inModal ? 'rgba(255,255,255,0.8)' : 'black';
  return (
    <RootSideBody>
      <RootsideBodyTop>
        <Typography color={colorWhite} variant='h2'>Propiedad</Typography>
        <Grid container flexDirection={'column'} maxWidth={160}>
          <Grid item xs={6} container justifyContent={'center'} alignItems={'center'}>
            {
              !inModal
                ? <WindowIcon onClick={onClickWindow}
                  style={{ width: 28, top: 4, position: 'relative', fill: colorWhite }} />
                : <RightScreenIcon onClick={onClickWindow}
                  style={{ width: 22, top: 7, position: 'relative', fill: colorWhite }} />
            }
          </Grid>
          <Grid item xs={6}>
            <CloseIcon onClick={onClose} style={{ width: 50, fill: colorWhite }} />
          </Grid>
        </Grid>
      </RootsideBodyTop>
      <PropertyView id={selectedProperty} fetchProperties={fetchProperties} onClose={onClose} />
    </RootSideBody>
  )
}

const CreateForm = ({ fetchProperties, onClickWindow, onClose, inModal }) => {
  const colorWhite = inModal ? 'rgba(255,255,255,0.8)' : 'black';
  return (
    <RootSideBody>
      <RootsideBodyTop>
        <Typography variant='h2' color={colorWhite} style={{marginLeft:20}}>Crear</Typography>
        <Grid container flexDirection={'column'}
          maxWidth={160}>
          <Grid item xs={6} container justifyContent={'center'} alignItems={'center'}>
            {
              !inModal
                ? <WindowIcon onClick={onClickWindow} style={{ width: 28, top: 4, position: 'relative', fill: colorWhite }} />
                : <RightScreenIcon onClick={onClickWindow} style={{ width: 22, top: 7, position: 'relative', fill: colorWhite }} />
            }
          </Grid>
          <Grid item xs={6}>
            <CloseIcon onClick={onClose} style={{ width: 50, fill: colorWhite }} />
          </Grid>
        </Grid>
      </RootsideBodyTop>
      <Form fetchProperties={fetchProperties} />
    </RootSideBody>
  )
}

export default Property